<template>
  <div class="mbox1">
    <h2>用户密码修改</h2>
    <el-form ref="formRef" :model="form" :rules="rules" label-width="120px">
      <input v-model="form.userId" type="hidden">
      <el-form-item label="用户名">
        {{username}}
      </el-form-item>      
      <el-form-item label="原密码" prop="currentPass">
        <el-input type="password" v-model="form.currentPass" show-password placeholder="请输入原密码" class="width"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPass">
          <el-input type="password" v-model="form.newPass" show-password placeholder="请输入新密码" class="width"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confPass">
          <el-input type="password" v-model="form.confPass" show-password placeholder="请输入确认密码" class="width"></el-input>
      </el-form-item>
    </el-form>
    <div class="mbox-footer">
      <el-button @click="resetForm">重 填</el-button>
      <el-button type="primary" @click="savePwd">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pwdedit',
  data () {
    var validatePass = (rule, value, callback) => {
      if (value !== this.form.newPass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      username: '',
      form: {
        userId: '',
        currentPass: '',
        newPass: '',
        confPass: ''
      },
      rules: {
        currentPass: [
          { required: true, message: '请输入原密码', trigger: 'blur' }
        ],
        newPass: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        confPass: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    // 获取本地信息 用户登录成功后取用户名
    const ui = this.$api.ui    
    if (ui !== null) {
      this.form.userId = ui.uid
      this.username = ui.uname
    } else {
      this.$router.push({ path: '/login' })            
    }    
  },
  methods: {
    async savePwd () {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {          
          // console.log('form=', this.form)
          const { data: ret } = await this.$http.post1(this.$api.userPwdEdit, this.form) 
          // console.log('retOK=', ret)
          if (ret.code === 1) {
            this.$message.success(ret.msg)            
          } else {
            this.$message.error(ret.msg)
          }
        } else {
          this.$message.error('验证没通过。')
          return false
        }
      })
    },
    resetForm () {
      this.$refs.formRef.resetFields()
    }
  }

}
</script>

<style>

</style>